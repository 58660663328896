$(document).ready(function(){
    _alert = (icon = '', title = '', message = '', confirmButton = false, cancelButton = false, timer = false) => {
        Swal.fire({
          title: title,
          html: message,
          icon: icon, 
          showConfirmButton: confirmButton,
          showCancelButton: cancelButton,
          confirmButtonText: "Oke",
          confirmButtonColor: "#ff0055",
          cancelButtonColor: "#999999",
          reverseButtons: true,
          focusConfirm: true,
          focusCancel: true,
          timer: timer,
          timerProgressBar: true,
        }).then( function(isConfirm){
    
          if(confirmButton){
              if(isConfirm.value){
                  location.reload();
              }
                  location.reload();
          }else{
              location.reload();
          }
    
        });
      }
    
    _alertAjax = (icon = '', title = '', message = '', confirmButton = false, cancelButton = false, timer = false) => {
        Swal.fire({
            title: title,
            html: message,
            icon: icon, 
            showConfirmButton: confirmButton,
            showCancelButton: cancelButton,
            confirmButtonText: "Oke",
            confirmButtonColor: "#ff0055",
            cancelButtonColor: "#999999",
            reverseButtons: true,
            focusConfirm: true,
            focusCancel: true,
            timer: timer,
            timerProgressBar: true,
        });
    }

    $(document).on('keypress', 'form', function(e){
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }
    });
    
});