$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    function generateShowModal()
    {
        $(document).find('body a[data-control=modal-content][data-control-mode=open]').on('click', function(e){
            e.preventDefault();
            var obj = $(this);

            let url = obj.attr('href');

            if($('.modal').length) {
                $('.modal').remove();
            }

            loadContentModal(url);
        });
    }

    function generateButtonShowModal()
    {
        $(document).find('body button[data-control=modal-content][data-control-mode=open]').on('click', function(e){
            e.preventDefault();
            var obj = $(this);

            let url = obj.data('url');

            if($('.modal').length) {
                $('.modal').remove();
            }

            loadContentModal(url);
        });
    }

    function generateButtonClassShowModal()
    {
        $(document).on('click', '.ajax-show-modal', function(e){
            e.preventDefault();
            var obj = $(this);

            let url = obj.data('url');
            var reloadView = 'load-ajax-view';
            let classReloadData = obj.data('reloadview');

            if(classReloadData !== 'undefined'){

                reloadView = classReloadData;

            }

            if($('.modal').length) {
                $('.modal').remove();
            }

            loadContentModal(url, reloadView);
        });
    }

    

    loadContentModal = (url, reloadView) => {
        $.ajax({
            type: 'get',
            url: url,
            contentType: "json",
            beforeSend: function (e) {

            },
            success: function (data) {
                $('body').append(data.html);

                var myModal = new bootstrap.Modal(document.getElementById('modal-form'), {
                    keyboard: false,
                    backdrop: 'static',
                    focus: true,
                    show: true
                });

                myModal.show();

                generateAjaxSubmit(reloadView);

            },
            error: function (response) {
                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax('error', '', response.message, true, true, false);
            }

        });
    }

    function generateAjaxSubmit(reloadView){
        // Send Form Data Text and File
        $('#buttonSubmit').click( function( e ){
            e.preventDefault();
            var obj = $(this);
            // var preloader = $('.pre-loader');
            var reloadViewData = $('.'+reloadView);
            obj.addClass('disabled');
            
            var submitForm  = document.getElementById('ajax-form');
            var data = new FormData(submitForm);

            var method = submitForm.getAttribute('method');
            var url = submitForm.getAttribute('action');

            $.ajax({
                type: method,
                enctype: 'multipart/form-data',
                url: url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // preloader.style.display = "block";
                },

            }).done( function(response){
                // preloader.style.display = "none";
                obj.removeClass('disabled');

                resetFeedback();
                $('.modal').hide();
                $('.modal-backdrop').remove();

                if(response.reloadViewDataStatus){
                    reloadViewData.html(response.reloadViewData);
                }
                
                //icon, title, text, confirmButton, cancelCutton, timer

                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

            }).fail( function(xhr, status, error){
                // preloader.style.display = "none";
                obj.removeClass('disabled');
                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generateAjaxDelete(){
        
        $(document).on('click', '.ajax-delete', function(e){
            e.preventDefault();
            var button = $(this);
            var message = button.attr('title');

            let $url = button.attr('href');
            button.addClass('disabled');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                
                }).done( function(response){
                    button.removeClass('disabled');
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, false, false, 1500);

                }).fail( function(xhr, status, error){
                    button.removeClass('disabled');

                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }
            
        });
    }

    function generateLoginAjax(){
        // Send Form Data Text and File
        $('#login-ajax').click( function( e ){
            e.preventDefault();
            var obj = $(this);
            var loading = document.getElementById('loading');
            obj.attr('disabled', 'disabled');
            
            var $submitForm  = document.getElementById('ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    loading.style.display = "block";
                },

            }).done( function(response){
                loading.style.display = "none";
                obj.removeAttr('disabled');

                resetFeedback();
                //icon, title, text, confirmButton, cancelCutton, timer

                _alert(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

            }).fail( function(xhr, status, error){
                loading.style.display = "none";
                obj.removeAttr('disabled');
                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generateLogoutForm()
    {
        $('.logout-form').click( function( e ){
            e.preventDefault();

            var $button = $(this);
            var $url = $button.attr('href');

            $.ajax({
                type: 'POST',
                url: $url,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();
                $('.modal').modal('hide');
                //icon, title, text, confirmButton, cancelCutton, timer

                _alert(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);

            });

        });
    }

    function generateAjaxSubmitForm(){
        $(document).on('click', '#buttonSubmitForm', function( e ){
            e.preventDefault();
            // Send Form Data Text and File
            var $button = $(this);
            var $submitForm  = document.getElementById('ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            var $urlRedirect = $button.attr('url-redirect');
            var $containerClass = $button.attr('containerClass');

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();
                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                _loadContentData($urlRedirect, $containerClass);

            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generatePageContentView()
    {
        $(document).on('click', '.getPageContent', function(e){
            e.preventDefault();
            var $button = $(this);
            var $url = $button.attr('url');
            var $containerClass = $button.attr('containerClass');

            _loadContentData($url, $containerClass);
        });
    }

    function generateFormData()
    {
        $(document).on('click', '.viewFormAjax', function(e){
            e.preventDefault();
            
            var $button = $(this);
            let $url = $(this).attr('url');
            var $containerClass = $button.attr('containerClass');

            _loadContentData($url, $containerClass);
            
        });
    }

    _loadContentData = ($url, $containerClass) => {
        $.ajax({
            type: 'GET',
            url: $url,
            dataType: 'json',
            cache: false,
            beforeSend: function(e){
                // console.log('beforeSend:'+e);
            },

        }).done( function(response){
            //icon, title, text, confirmButton, cancelCutton, timer
            $('.'+$containerClass).html(response.html);

        }).fail( function(xhr, status, error){

            _alertAjax('error', '', error, true, true, false);
        });
    }

    function generateDeleteFormAjax(){
        
        $(document).on('click', '.deleteFormAjax', function( e ){
            e.preventDefault();
            var $button = $(this);
            var message = $button.attr('title');

            var $url = $button.attr('href');
            $button.addClass('disabled');

            var $urlRedirect = $button.attr('url-redirect');
            var $containerClass = $button.attr('containerClass');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                }).done( function(response){
                    $button.removeClass('disabled');
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                    _loadContentData($urlRedirect, $containerClass);
                }).fail( function(xhr, status, error){
                    $button.removeClass('disabled');
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }
            
        });
    }

    getFeedback = (errors) => {
        $.each(errors, function(error, e){
            $("input[name='"+error+"']").addClass('is-invalid');
            $('.error-'+error).html(e);
        });
    }

    resetFeedback = () => {
        $(".form-control").removeClass('is-invalid');
        $(".form-note").html('');
    }

    function countVisitor()
    {
        $(document).on('click', '.count-visitor', function(e){
            var obj = $(this);
            var url = obj.data('url');

            $.ajax({
                type: 'GET',
                url: url,
                contentType: "application/json",
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // 
                }
            
            });

        });
    }

    $(document).on('click','.toggle-old-password',function(){
        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#old_pass_log_id");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $(document).on('click','.toggle-password',function(){
        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#pass_log_id");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $(document).on('click','.toggle-confirmation-password',function(){
        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#confirmation_pass_log_id");

        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    function generateAjaxPostDelete()
    {
        $(document).find('body a[data-control=link-post-delete][data-control-mode=ajax]').on('click', function(e){
            e.preventDefault();

            var button = $(this);
            var message = button.attr('title');

            var url = button.attr('href');
            button.addClass('disabled');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);
                    }
                }).done( function(response){
                    button.removeClass('disabled');
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alert(response.status, '', response.message, response.confirm, response.cancel, response.timer);
                }).fail( function(xhr, status, error){
                    button.removeClass('disabled');
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alert(status, '', error, true, true, false);
                });
            }

        });
    }

    generateRemoveClassAndStyleOnBodyTag = () => {
        document.body.classList.remove('modal-open');
        document.body.style.overflow = '';
    }

    generateLoginAjax();
    generateAjaxSubmit();
    generateShowModal();
    generateButtonShowModal();
    generateButtonClassShowModal();
    generateAjaxDelete();
    generateDeleteFormAjax();

    generateLogoutForm();
    generatePageContentView();
    generateFormData();

    generateAjaxSubmitForm();

    countVisitor();

    generateAjaxPostDelete();

});